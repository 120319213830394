import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// Components
import ServiceCard from '../../components/card/Service';

const ServicesSection = ({ site, city, sectionStyle }) => (
  <StaticQuery
    query={graphql`
      query {
        services: allMdx(
          filter: {
            fileAbsolutePath: { regex: "/data/services/" },
            frontmatter: { home: { eq: true }, active: { eq: true } }
          },
          sort: {fields: frontmatter___order, order: ASC}
        )
        {
          nodes {
            frontmatter {
              title
              description
              image {
                icon {
                  childImageSharp {
                    gatsbyImageData
                    fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                card {
                  childImageSharp {
                    gatsbyImageData
                    fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                alt
                credit {
                  text
                  link
                }
              }
              home
              active
              date(formatString: "D MMMM YYYY", locale: "fr")
            }
            id
            slug
          }
        }
      }
    `}
    render={data => (
      <>
        {sectionStyle === "1" &&
          <div id="services" className="wpo-service-area section-padding">
            <div className="container">
              <div className="row">
                <div className="wpo-section-title">
                  <span>Nos services</span>
                  <h2>Ce que nous faisons</h2>
                </div>
              </div>
              <div className="row align-items-top">
                {data.services.nodes.map(service => (
                  <ServiceCard key={service.id} data={service} cardStyle="1" cardClassName="col-lg-4 col-md-6 col-12 mb-4" />
                ))}
              </div>
            </div>
          </div>
        }
      </>
    )}
  />
)

export default ServicesSection