import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../components/layout/Layout';
import AboutSection from '../components/section/About';
import RealisationsSection from '../components/section/Realisations';
import ServicesSection from '../components/section/Services';
import CountsSection from '../components/section/Counts';
import TimelineSection from '../components/section/Timeline';
import RSESection from '../components/section/RSE';
import TeamSection from '../components/section/Team';
import PartnersSection from '../components/section/Partners';
import TestimonialsSection from '../components/section/Testimonials';

const AboutPage = () => {

  const query = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          description
          image {
            url
            secureUrl
            type
            width
            height
            alt
          }
          addressStreet
          addressPostalCode
          addressCity
          addressCountry
          addressGoogleDirection
          email
          telephone
          telephoneIndex
          schedules
          socialNetworks {
            facebook
            instagram
            linkedIn
          }
          author {
            name
            url
            creator
            image {
              url
            }
          }
          publisher {
            name
            url
            logo {
              url
            }
          }
        }
      }
      headerBackgroundImage: file(relativePath: { eq: "headers/a-propos-mousse-gava.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Set ImageData.
  const headerBackgroundImage = query.headerBackgroundImage.childImageSharp.fluid
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.1))`,
  ].reverse()

  return (
    <Layout
      pageTitle={`À propos | ${query.site.siteMetadata.title} - Plâtrerie-peinture, décoration, carrelage, faïence, sols souples, façade et isolation intérieure à Roanne`}
      pageDescription={`${query.site.siteMetadata.title} est une entreprise basée à Roanne spécialisée dans la plâtrerie-peinture, décoration, carrelage, faïence, sols souples, façade et isolation thermique par l'intérieure.`}
      pageKeywords="moussé gava, roanne, coteau, loire, travaux, sols souples, rénovation, plâtrerie, peinture, décoration, aménagement, façade, isolation, Qualibat RGE"
      headerClassName="wpo-site-header wpo-header-style-2"
    >
      <BackgroundImage
        Tag="section"
        className="wpo-page-title"
        fluid={headerBackgroundImageStack}
      >
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <h2>À propos</h2>
                <ol className="wpo-breadcumb-wrap">
                  <li><Link to={`/`}>Accueil</Link></li>
                  <li>À propos</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <AboutSection site={query.site} sectionStyle="2" />
      <RealisationsSection site={query.site} sectionStyle="1" />
      <ServicesSection site={query.site} sectionStyle="1" />
      <CountsSection site={query.site} sectionStyle="2" />
      <RSESection site={query.site} sectionStyle="1" />
      <TimelineSection site={query.site} sectionStyle="1" />
      <TeamSection site={query.site} sectionStyle="1" />
      <PartnersSection site={query.site} sectionStyle="1" />
      <TestimonialsSection site={query.site} sectionStyle="1" />
    </Layout>
  )
}

export default AboutPage
