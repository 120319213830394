import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Tilty from 'react-tilty';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const RSESection = ({ site, city, sectionStyle }) => {
  return (
    <>
      {sectionStyle === "1" &&
        <div id="rse" className="wpo-about-area-s2 section-padding">
          <div className="container">
            <div className="row align-items-top">
              <div className="col-12">
                <div className="wpo-about-text">
                  <div className="wpo-about-title">
                    <span>Notre engagement</span>
                    <h2>Une expertise responsable à votre service</h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="wpo-about-text">
                  <p>{site.siteMetadata.title} est reconnue pour son savoir-faire et son engagement envers la qualité et le développement durable. La Responsabilité Sociétale des Entreprises (RSE) est au cœur de son activité :</p>
                  <ul className="ab-list">
                    <li>Nouveaux véhicules de fonction hybrides</li>
                    <li>Jardin collaboratif</li>
                    <li>Mise en place de visio' avec les partenaires lorsque le déplacement est conséquent</li>
                    <li>Utilisation de tasses réutilisables</li>
                    <li>Gestion stricte des déchets de chantier</li>
                    <li>Utilisation d'éclairages LED et de détecteurs de mouvement dans les parties dépôts</li>
                    <li>Appareils électroniques reconditionnés</li>
                    <li>Réfection des locaux et isolation thermique pour réduire la consommation d'énergie</li>
                    <li>Projet de système de récupération d'eaux de pluie (en cours)</li>
                    <li>Dématérialisation et digitalisation afin de supprimer un maximum de papier et favoriser la transparence</li>
                  </ul>
                  <div className="btns">
                    <AnchorLink className="theme-btn" href="#services" aria-label="services">Découvrez nos services</AnchorLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="wpo-about-img align-top">
                  <div className="wpo-about-img-left mb-5">
                    <Tilty className="tilty" glare scale={1.05} maxGlare={0.5}>
                      <StaticImage src="../../images/rse/jardin-collaboratif-entreprise-mousse-gava-riorges.png" alt={`Aménagement d'un jardin collaboratif de l'entreprise ${site.siteMetadata.title} à Riorges`} width={500} height={300} />
                    </Tilty>
                  </div>
                  <div className="wpo-about-img-right mt-5">
                    <Tilty className="tilty mt-5" glare scale={1.05} maxGlare={0.5}>
                      <StaticImage src="../../images/rse/jardin-collaboratif-entreprise-mousse-gava-roanne.png" alt={`Aménagement d'un jardin collaboratif pour les employés de l'entreprise ${site.siteMetadata.title} à Roanne`} width={500} height={300} />
                    </Tilty>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default RSESection