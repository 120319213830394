import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const TimelineSection = ({ site, sectionStyle }) => {

  return (
    <>
      {sectionStyle === "1" &&
        <section id="timeline" className="section-padding">
          <div className="container">
            <div className="row">
              <div className="wpo-section-title">
                <div>
                  <StaticImage imgClassName="img-thumnail" src="../../images/logos/mousse-gava.png" alt={site.siteMetadata.image.alt} width={300} height={150} />
                </div>
                <h3>À votre service depuis 70 ans</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="tm-timeline-responsive-vertical-cp">
                  <div className="timeline__block">
                    <div className="timeline__midpoint timeline__midpoint--highlight timeline__midpoint--withtext no-top-margin d-flex align-items-center">
                      <div className="timeline__year">
                        <p>1953</p>
                      </div>
                    </div>
                    <div className="timeline__content timeline__content--left">
                      <div className="timeline__text--left">
                        <div className="timeline-content">
                          <h4>Création</h4>
                          <p>L'entreprise a été créée en 1953. Moussé Gava spécialisée dans la platerie-peinture, isolation, revêtements de sols, carrelages, décorations et façade depuis toutes ces années.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="timeline__block">
                    <div className="timeline__midpoint false"></div>
                    <div className="timeline__content timeline__content--right">
                      <div className="timeline__year">
                        <h2>2005</h2>
                      </div>
                      <div className="timeline__text--right">
                        <div className="timeline-content">
                          <h4>Reprise</h4>
                          <p>Gilles Coppéré devient le nouveau gérant de la société Moussé Gava.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="timeline__block">
                    <div className="timeline__midpoint false"></div>
                    <div className="timeline__content timeline__content--left">
                      <div className="timeline__year">
                        <h2>2012</h2>
                      </div>
                      <div className="timeline__text--left">
                        <div className="timeline-content">
                          <h4>Effiréno</h4>
                          <p>Effiréno regroupe 5 entreprises du bâtiment spécialiste de la rénovation de l'habitation et de l'aménagement intérieur à Roanne et dans la région.</p>
                          <p>Les cinq entreprises du groupement possèdent les qualifications Qualibat et RGE - reconnu garant de l'environnement. Cela garantit un travail de qualité, mais aussi l'accès aux différentes aides financières pour mener à bien votre projet. Effiréno répond à l'ensemble des besoins : menuiserie, charpente, plomberie, sanitaire, chauffage, énergies renouvelables, électricité, photovoltaïque, plâtrerie, peinture, carrelage et maçonnerie.</p>
                          <p>Un seul interlocuteur pour gérer l'ensemble de votre projet dans les délais fixés.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="timeline__block">
                    <div className="timeline__midpoint false"></div>
                    <div className="timeline__content timeline__content--right">
                      <div className="timeline__year">
                        <h2>2015</h2>
                      </div>
                      <div className="timeline__text--right">
                        <div className="timeline-content  ">
                          <h4>Changement de direction</h4>
                          <p>Arrivée de Romain Coppéré à la direction de l'entreprise.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="timeline__block">
                    <div className="timeline__midpoint false"></div>
                    <div className="timeline__content timeline__content--left">
                      <div className="timeline__year">
                        <h2>2019</h2>
                      </div>
                      <div className="timeline__text--left">
                        <div className="timeline-content  ">
                          <h4>RGE et Bâtir avec l'Environnement</h4>
                          <p>L'entreprise a mis en place un recyclage complet des déchets avec traçabilité, un co-voiturage, ainsi que des trajets de livraison par anticipation, optimisés, tout en privilégiant l'utilisation de matériaux ayant une empreinte carbone la plus faible possible.</p>
                          <p>Les peintures base acrylique utilisées sont labellisées éco-responsables, avec une faible émissivité de composés organiques volatiles (COV) pour préserver la qualité de l'air intérieur de votre habitat. Moussé Gava a également investi dans une machine à laver les rouleaux de peinture. L'objectif est de ne plus rejeter de peinture dans les circuits publics d'évacuation, d'économiser l'eau et de récupérer les déchets de peinture afin de les recycler. Cet achat onéreux n'a pas d'autre objectif que de réduire l'impact de l'entreprise sur l'environnement à l'heure où le secteur du bâtiment est souvent montré du doigt.</p>
                          <p>Ces efforts, tant financiers qu'organisationnels, ont permis à l'entreprise d'être labellisée RGE (reconnu garant de l'environnement) et « Bâtir avec l'Environnement », soit un engagement signé pour une démarche qualité et éco-responsable. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="timeline__block">
                    <div className="timeline__midpoint false"></div>
                    <div className="timeline__content timeline__content--right">
                      <div className="timeline__year">
                        <h2>2022</h2>
                      </div>
                      <div className="timeline__text--right">
                        <div className="timeline-content  ">
                          <h4>Reprise</h4>
                          <p>Reprise de l'entreprise par Romain Coppéré</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*
                  <div className="timeline__block">
                    <div className="timeline__midpoint false"></div>
                    <div className="timeline__content timeline__content--left">
                      <div className="timeline__year">
                        <p>2012</p>
                      </div>
                      <div className="timeline__text--left">
                        <div className="timeline-content  ">
                          <h4>This is custom heading element</h4>
                          <p>I am text block. Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="timeline__block">
                    <div className="timeline__midpoint timeline__midpoint--highlight timeline__midpoint--withtext no-top-margin d-flex align-items-center">
                      <div className="timeline__year">
                        <p>2012</p>
                      </div>
                    </div>
                    <div className="timeline__content timeline__content--left">
                      <div className="timeline__year timeline__year--mobile">2012</div>
                      <div className="timeline__text--left">
                        <div className="timeline-content"></div>
                      </div>
                    </div>
                  </div>
                  <div className="timeline__block">
                    <div className="timeline__midpoint false"></div>
                    <div className="timeline__content timeline__content--right">
                      <div className="timeline__year">
                        <p>2013</p>
                      </div>
                      <div className="timeline__text--right">
                        <div className="timeline-content">
                          <h4>This is custom heading element</h4>
                          <p>I am text block. Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  */}
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}

export default TimelineSection